/* Note: use only to override very specific theme styles. 
All other css rules should be written in their own respective sass documents. */

/* ADMIN > ENTITIES MANAGEMENT > PRIMARY HEADER (QUICK FIX) */
.manage-entities-header h4 {
  font-size: 34px !important;
}


.animate-onhover-primary {
  position: relative;
  display: inline-block;
}
.animate-onhover-primary::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 10px;
  left: 0;
  background-color: #676767;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}
.animate-onhover-primary:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}